import { graphql } from "gatsby"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Button, { buttonPropsSchema } from "../../freestanding/button/button"
import Highlight from "../../freestanding/highlight"
import Section from "../../freestanding/section"

export const ctaFillPropsSchema = z.object({
  title: z.string(),
  description: z.string(),
  buttons: buttonPropsSchema.array().nullish(),
  image: imagePropsSchema.nullish(),
  className: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
})

const CTAFill = ({
  title = "",
  description,
  buttons,
  image,
  className,
}: z.infer<typeof ctaFillPropsSchema>) => {
  return (
    <Section className={className}>
      <div className="flex flex-col gap-8 bg-gray-900 p-8 shadow-2xl lg:flex-row lg:p-12">
        <div className="flex flex-grow self-center">
          <div className="max-w-md text-center lg:max-w-[60ch] lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              <Highlight>{title}</Highlight>
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              <Highlight>{description}</Highlight>
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              {buttons?.map((button, index) => (
                <Button
                  key={index}
                  {...button}
                  iconRight="arrow-right"
                  className="w-full whitespace-nowrap md:w-min"
                />
              ))}
            </div>
          </div>
        </div>
        {image && (
          <div className="aspect-square lg:w-1/2 xl:w-1/3">
            <Image {...image} className="object-cover" />
          </div>
        )}
      </div>
    </Section>
  )
}

export default CTAFill

export const query = graphql`
  fragment CTAFill on PagesJson {
    blocks {
      id
      type
      title
      description
      className
      buttons {
        button {
          title
          to
          variant
          analytics {
            event
            category
            name
            action
            track {
              GenericPlacement
              GenericVariant
              GenericValue
              GenericCurrency
            }
          }
        }
      }
      photo {
        alt
        padding
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        backgroundColor
        objectFit
      }
    }
  }
`
